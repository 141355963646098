import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout/Layout'
import Seo from '../../components/Seo/Seo'
import CallToAction from '../../components/CallToAction/CallToAction';

const Container = styled.div`
    max-width: 1080px;
    margin: 80px auto;
    display: block;

    text-align: center;
`

const Thanks = () => {
    return (
        <>
        <Seo title="Thanks!" />
            <Layout>
                <Container>
                    <h1>Thank you!</h1>
                    <p>I will get back to you as soon as possible.</p>
                </Container>
            <CallToAction header="Back to home" text="Home page" to="/" />
        </Layout>
      </>
    );
};

export default Thanks;